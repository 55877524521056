<template>
    <div class="al-content">
        <div class="content-top">
            <div class="content-top clearfix">
                <h1 class="al-title ">Sale Zones</h1>
            </div>
        </div>
        <div>
            <button type="button" class="btn btn-default float-left mb-3" @click="addZone"><i class="ion-plus"></i> Add</button>
        </div>
        <div>

            <table class="table table-hover table-bordered" id="datatable">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Updated</th>
                        <th>Updated By</th>
                        <th v-show="checkAcl('Edit')"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in data" :key="item.id">
                        <td>{{item.Id}}</td>
                        <td>{{item.Name}}</td>
                       
                        <td>{{item.Updated}}</td>
                        <td>{{item.UpdatedBy}}</td>
                        <td v-show="checkAcl('Edit')">
                            <button type="button" class="btn btn-default btn-sm" @click="ShowEdit(item.Id)" title="Edit">
                                <i class="ion-edit p-1"></i>
                            </button>
                            <button type="button" class="btn btn-danger btn-sm" @click="Remove(item)" title="Edit">
                                <i class="ion-trash-a p-1"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>

        </div>


        <MDBModal id="editModal"
                  tabindex="-1"
                  staticBackdrop
                  labelledby="exampleModalLabel"
                  v-model="showEditForm"
                  size="lg">
            <MDBModalHeader>
                <MDBModalTitle id="editModalTitle" v-html="editTitle"></MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>

                <MDBRow id="editForm" tag="form" class="g-3">

                    <MDBCol md="12">
                        <MDBInput label="Name *"
                                  id="Name"
                                  v-model="Name"
                                  invalidFeedback="Please provide a valid Name"
                                  validFeedback="Looks good!"
                                  title="NO special characters, the length should be between 2 and  50 characters" />
                    </MDBCol>


                </MDBRow>

            </MDBModalBody>
            <MDBModalFooter>
                <button type="submit" class="btn btn-success" @click="doSubmit">
                    {{editBtnText}}
                </button>
                <button type="button" class="btn btn-danger" @click="showEditForm=false">
                    Cancel
                </button>

            </MDBModalFooter>
        </MDBModal>


    </div>
</template>

<script>
    //https://therichpost.com/vue-3-datatable-with-export-buttons-print-csv-copy-with-dynamic-data/
    //Datatable Modules
    import "datatables.net-dt/js/dataTables.dataTables"
    import "datatables.net-dt/css/jquery.dataTables.min.css"
    import "datatables.net-buttons/js/dataTables.buttons.js"
    import "datatables.net-buttons/js/buttons.colVis.js"
    import "datatables.net-buttons/js/buttons.flash.js"
    import "datatables.net-buttons/js/buttons.html5.js"
    import "datatables.net-buttons/js/buttons.print.js"
    import 'datatables.net-responsive'

    import $ from 'jquery'

    //https://mdbootstrap.com/docs/b5/vue/components/modal/#docsTabsAPI
    import 'mdb-vue-ui-kit/css/mdb.min.css';
    import {
        //MDBBtn,
        MDBModal,
        MDBModalBody,
        MDBModalHeader,
        MDBModalTitle,
        MDBModalFooter,
        MDBCol, MDBRow,
        MDBInput
    } from 'mdb-vue-ui-kit';


    import { mapState,mapActions } from 'vuex'
    export default {
        name: `Zone`,
        data: function () {
            return {
                LayoutModel: {},
                data: [],
                NameExisted: [],
                //=== edit popup====
                editId: 0,
                showEditForm: false,
                editTitle: 'Edit',
                editBtnText: 'Save',
                Name:'',
            }
        },
        components: {
            //MDBBtn,
            MDBModal,
            MDBModalHeader,
            MDBModalTitle,
            MDBModalBody,
            MDBModalFooter,
            MDBCol, MDBRow,
            MDBInput
        },
        computed: mapState({
            isCheckedLogin: state => state.view.isCheckedLogin,
        }),
        methods: {
            ...mapActions({
                'loading': 'view/setLoading',
                'showModal': 'view/showModal',
                'showToast': 'view/showToast',
                'setGroups': 'user/setGroups',
                
                'call': 'api/call'
            }),
            checkAcl(action) {
                return this.$store.state.session.acl.includes(this.$options.name + "/" + action);
            },
            checkFullAcl(router) {
                return this.$store.state.session.acl.includes(router);
            },
            start() {
                this.getAll();                
            },
            addZone() {

                this.editId = 0;
                this.editTitle = "New Zone"
                this.editBtnText = "Add";
                this.Name = '';
                this.showEditForm = true;
                setTimeout(() => { this.validName()}, 100);

            },
            validName() {
                return this.validTextField(this.Name, 'Name', true, /.*/, '', 2, 100, this.NameExisted, this.editId);
            },
            validTextField(value, id, required, regex, regexMessage, minlen, maxlen, NameExisted, editedId) {
                if (!id || id == '') return;
                let error = "";
                minlen = minlen || 0;
                maxlen = maxlen || 1000;
                editedId = editedId || 0;
                const checkedvalue = value.trim();
                const isCheckRequire = required || (!required && checkedvalue.length > 0);

                if (required && checkedvalue == '') {
                    error = "Please input this field!";
                }
                //check name existed
                else if (NameExisted != null && NameExisted != undefined &&
                    (NameExisted[checkedvalue] != undefined && editedId != NameExisted[checkedvalue] || (Array.isArray(NameExisted) && NameExisted.includes(checkedvalue)))
                ) {
                    error = id + " is duplicated!";
                }
                //check pattern
                else if (isCheckRequire && regex != null && regex != undefined && !regex.test(checkedvalue))
                    error = regexMessage || `Invalid ${id}!`;
                //check len

                else if (isCheckRequire && (checkedvalue.length < minlen)) {
                    error = `Please input at least ${minlen} characters!`;
                }
                else if (checkedvalue.length > maxlen) {
                    error = `Please do not exceed ${maxlen} characters!`;
                }

                //return value
                if (error == '') {
                    if ($('#' + id).length > 0) {
                        $('#' + id).removeClass('is-invalid');
                        $('#' + id).addClass('is-valid');
                    }
                    return true;
                } else {
                    if ($('#' + id).length > 0) {
                        $('#' + id + '~.invalid-feedback')[0].innerHTML = error;
                        $('#' + id).addClass('is-invalid');
                        $('#' + id).removeClass('is-valid');
                    }
                    $('#' + id).focus();
                    return false;
                }
            },
            async getAll() {
                this.loading(true);
                const rs = await this.call({ Action: 'Zones/GetAll' })
                this.loading(false);
                this.error = rs.Error;
                if (rs.Error != "") {
                    //show modal with callback name = loginView to check it whenever modal confirm triggerConfirm
                    this.showModal({ isShow: true, title: 'Error', content: rs.Error, type: 'error' });
                    //no permission                    
                    if (rs.Error.includes("Permission Denied")) {
                        window.history.back();
                    }
                } else {                    
                    this.data = rs.Data;
                    if (this.dataTable != null) this.dataTable.destroy();
                    setTimeout(this.doTable, 100);
                }
            },
            ShowEdit(itemid) {
                for (let i = 0; i < this.data.length; i++) {
                    const item = this.data[i];
                    if (item.Id == itemid) {
                        this.editTitle = "Zone <b>" + item.Name + "</b>";
                        this.editBtnText = "Save";
                        this.editId = itemid;
                        this.Name = item.Name;
                        this.showEditForm = true;
                        return;
                    }
                }
                this.showToast({ isShow: true, title: '', content: 'Zone not found!', type: 'error' });
            },
            async doSubmit() {
                //======= validate =========
                //$('#editForm').addClass('was-validated');

                let isValid = true;
                isValid = isValid && this.validName();

                if (!isValid) {
                    this.showToast({ isShow: true, title: '', content: 'Form is invalidated.', type: 'error' });
                    return;
                }

                //======== do submit =========
                this.loading(true);

                const rs = await this.call({
                    Action: 'Zones/Save', Object: {
                        Id: this.editId,
                        Name: this.Name,
                    }
                });
                this.loading(false);
                this.error = rs.Error;
                if (rs.Error != "") {
                    this.showToast({ isShow: true, title: '', content: rs.Error, type: 'error' });
                } else {
                    let action = 'Save';
                    if (this.editId == 0) action = 'Add';
                    this.showToast({
                        isShow: true, title: '', content: `<span style="font-weight:bold">${action} ${this.Name}</span> <span style="font-weight: bold;color: blue;">successful!</span> `, type: 'success'
                    });
                    if (action == 'Save') {
                        for (let i = 0; i < this.data.length; i++) {
                            if (this.data[i].Id == this.editId) {
                                this.data[i].Name = this.Name;
                            }
                        }
                    }
                    else this.getAll();
                    this.showEditForm = false;
                }
            },
            async toggleDisable(itemId) {
                if (!(this.checkAcl("Edit"))) {
                    this.showToast({ isShow: true, title: '', content: `No permission!`, type: 'error' });
                    return;
                }
                this.loading(true);
                const rs = await this.call({ Action: 'Zones/toggleDisable', Object: { Id: itemId } })
                this.loading(false);
                this.error = rs.Error;
                if (rs.Error != "") {
                    //show modal with callback name = loginView to check it whenever modal confirm triggerConfirm
                    this.showModal({ isShow: true, title: 'Error', content: rs.Error, type: 'error' });
                } else {
                    for (let i = 0; i < this.data.length; i++) {
                        if (this.data[i].Id == itemId) {
                            this.data[i].IsDisable = !this.data[i].IsDisable;
                            this.showToast({
                                isShow: true, title: '', content: `<span style="font-weight:bold">${this.data[i].Name}</span> is <span style="font-weight: bold;` + (!this.data[i].IsDisable ? 'color: blue;">enabled' : 'color: red;">disabled') + `</span> `, type: 'success'
                            });
                            break;
                        }
                    }
                    
                }
            },
            doTable() {
                const _this = this;
                _this.loaded = true;

                _this.dataTable = $('#datatable').DataTable(
                    {
                        processing: true,
                        responsive: true,
                    }
                );
                _this.loading(false);
            },
            Remove(item) {
                this.showModal({
                    isShow: true, title: 'Warning', content: "Are you sure to delete this Zone <br /><b>" + item.Name + "</b>?", type: 'error', callback: () => {

                        this.DoRemove(item);
                    }, isConfirm: true
                });
            },
            async DoRemove(item) {
                this.loading(true);

                const rs = await this.call({
                    Action: 'Zones/Remove', Object: {
                        Id: item.Id,
                        Name:item.Name,
                    }
                })
                this.loading(false);
                this.error = rs.Error;
                if (rs.Error != "") {
                    //show modal with callback name = loginView to check it whenever modal confirm triggerConfirm
                    this.showModal({ isShow: true, title: 'Error', content: rs.Error, type: 'error' });

                } else {
                    this.getAll();
                    this.showToast({ isShow: true, title: 'Success', content: "Remove " + item.Name + " successful.", type: 'success' });
                }
            },
        },

        created() {
            
        },
        mounted() {            
            //if already login then start, 
            //else will watch isCheckedLogin to catch its change
            //to call start function
            if (this.isCheckedLogin) this.start();
        },
        watch: {
            //catch status from not login to logged in 
            //to call start function.
            //Apply for reload page issue
            isCheckedLogin(newVal) {
                console.log("user index newVal", newVal);
                if (newVal) {
                    this.start();
                }
            },
            Name() {
                setTimeout(this.validName, 100);
            },
        }
    };
</script>
